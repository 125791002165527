import { useState, useEffect, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import styles from './popUpParameters.module.scss';
import { ChatUserContext } from '../../../../core/chatContext';

const PopUpParameters: React.FC<{ visible: boolean, setIsVisibleParams: any }> = ({ visible, setIsVisibleParams }) => {
    const currChatContext: any = useContext(ChatUserContext);

    const [isVisible, setIsVisible] = useState(false);
    const [numTokens, setTokens] = useState(currChatContext.currChat.max_tokens);
    const [temperature, setTemperature] = useState(currChatContext.currChat.temperature);

    useEffect(() => {
        setIsVisible(visible);
    },[visible]);


    const closeParamsPopUp = () => {
        setIsVisible(!isVisible);
        setIsVisibleParams(false);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        currChatContext.setCurrChat((prevCurrChat: any) => {
            prevCurrChat.max_tokens = numTokens;
            prevCurrChat.temperature = temperature;
            return prevCurrChat;
        });
        closeParamsPopUp();
    };

    return(
        <div className={styles.popUp} style={{display: isVisible ? 'block' : 'none'}}>
            <div className={styles.formCloseIcon}>
                <CloseIcon sx={{ fontSize: 12 }} onClick={closeParamsPopUp}></CloseIcon>
            </div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formTokens}>
                    <label htmlFor="numTokens">Número Tokens:</label>
                    <input
                        type="number"
                        id="numTokens"
                        min="0"
                        value={numTokens}
                        onChange={(e) => setTokens(Math.max(0, Number(e.target.value)))}
                        required
                    />
                </div>
                <div className={styles.formTemperature}>
                    <div className={styles.formTemperatureLine}>
                        <label htmlFor="temperature">Temperatura:</label>
                        <span className={styles.formTemperatureValue}>{temperature.toFixed(1)}</span>
                    </div>
                    <input
                        type="range"
                        id="temperature"
                        min="0"
                        max="2"
                        step="0.1"
                        value={temperature}
                        onChange={(e) => setTemperature(Number(e.target.value))}
                    />
                </div>
                <button type="submit" className={styles.formButton}>Aplicar</button>
            </form>
        </div>
    )
}

export {PopUpParameters}