import { useState, createRef, useContext } from 'react';

import { ChatUserContext, iChatUserContext } from '../../../core/chatContext';

import styles from './index.module.scss';
import Send from '@mui/icons-material/Send';


const PromptWithOutAttachedComponent = () => {
    const currChatContext: iChatUserContext | null = useContext(ChatUserContext);

    const [formData, setFormData] = useState({ question: "" });

    const textAreaRef = createRef<HTMLTextAreaElement>();
    
    const handleTextareaKeyPress = (e: any) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();

      if (formData.question && currChatContext) {
        // Add new question to array
        currChatContext['setCurrChat']((prevContext: RequestOpenAI) => {
          prevContext.messages = [...prevContext.messages || [], {
            "role": "user",
            "content": formData.question
          }]
          return prevContext
        });

        // Update user question
        currChatContext.setUserAsks((prevArray: any) => [...prevArray, formData.question]);

        // Add loading message
        currChatContext.setIaAnswers((prevArray: DialogComponents[]) => [...prevArray, {id: '0', content: 'Generando respuesta...'}]);

        // Chat_Controller.proccessResponse(currChatContext, idUser, formData.question)
      }
    };

    return (
        <div className={styles.prompt}>
            <form className={styles.promptInput} onSubmit={handleSubmit}>
                <div className={styles.promptInputTextareaContainer}>
                    <textarea 
                        ref={textAreaRef}
                        className={styles.promptInputTextarea} 
                        onChange={(e) => {
                            setFormData({...formData, question: e.target.value})
                            e.target.style.height = 'auto'
                            e.target.style.height = `${e.target.scrollHeight}px`
                        }}
                        onKeyDown={handleTextareaKeyPress} // Handles the "Enter" key
                        value={formData.question} 
                        id="body"
                        name="body" 
                        placeholder='En qué te puedo ayudar'
                        rows={1}
                        style={{overflowY: formData.question.split('\n').length > 5 ? 'auto' : 'hidden'}}></textarea>
                </div>
                <button className={styles.promptSendButton} type='submit'>
                    <Send className={styles.promptIcon}></Send>
                </button>
            </form>
        </div>
    ) 
}

export {PromptWithOutAttachedComponent}

