/* dialog.tsx */
import { Fragment, useContext, useEffect } from 'react';
import { FeedbackComponent } from '../../chat/feedBack/feedBack';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

import { formatHtml } from '../../../core/formatHtml';
import { ChatUserContext } from '../../../core/chatContext';

import logoHAI from './../../../assets/img/Vortex_AI_Logo.svg';

import styles from './index.module.scss';



const AskComponent = (props: { userPhoto: any, userAsks: any }) => {
    const {userPhoto, userAsks} = props;

    return (
        <div className={styles.dialogContainerAskComponent}>
            <div className={styles.dialogContainerAskComponentText}>
                <ReactMarkdown className={styles.markdownTable} remarkPlugins={[remarkGfm]}>{typeof userAsks === 'string' ? formatHtml(userAsks) : null}</ReactMarkdown>
            </div>
            <div>
                { userPhoto && <img src={userPhoto} className={styles.dialogContainerPhoto} alt="Foto de perfil" />}
            </div>
        </div>
    )
}

const AnswerComponent = (props: { iaAnswers: DialogComponents }) => {
    
    const {iaAnswers} = props;

    const SpinnerComponent = () => {
        return iaAnswers.content === "Generando respuesta..." ? (<p className={styles.loader}></p>) : (<></>);
    }

    if (!iaAnswers) return (<></>); 
    return (
        <div className={styles.dialogContainerAnswerComponent}>
            <div className={styles.dialogContainerChatAvatar}>
                <img src={logoHAI} alt="logo" />
            </div>
            <div className={styles.dialogContainerAnswerComponentText}>
                <SpinnerComponent></SpinnerComponent>
                <ReactMarkdown className={styles.markdownTable} remarkPlugins={[remarkGfm]}>{typeof iaAnswers.content === 'string' ? formatHtml(iaAnswers.content) : null}</ReactMarkdown>
            </div>
        </div>
    )
}

const DialogComponent: React.FC<{userPhoto: string, userAsks: string[], iaAnswers: DialogComponents[], enabledFeedBack: boolean}> = ({userPhoto, userAsks, iaAnswers, enabledFeedBack}) => {    

    const currChatContext: any = useContext(ChatUserContext);
    
    const lines = userAsks.length; 

    useEffect(() => {
        // Move scroll to bottom when update userAsks to see last response of IA
        const elemento = document.getElementById('bottomDialogContainer');
        elemento?.scrollIntoView({ behavior: 'smooth' });
    },[userAsks, iaAnswers]);

    return (
        <div className={styles.dialogComponent}>
            <div className={styles.dialogContainer}>
                {[...Array(lines)].map((elementInArray, index) => ( 
                    <Fragment key={index}>
                        <AskComponent key={`ask-${index}`} userPhoto={userPhoto} userAsks={userAsks[index]}></AskComponent>
                        <AnswerComponent key={`answer-${index}`} iaAnswers={iaAnswers[index]}></AnswerComponent>
                        {
                            enabledFeedBack && 
                            <FeedbackComponent conversationId={currChatContext.currChat.data_sources[0].parameters.conversationId} idx={iaAnswers[index].id}></FeedbackComponent>

                        }
                    </Fragment>
                    ) 
                )}
                <div id="bottomDialogContainer"></div>
            </div>
        </div>
    )
}

export { DialogComponent }