// chat.tsx

import styles from './chat.module.scss';
import Logout from '@mui/icons-material/Logout';

import { useEffect, useState } from 'react';
import { InitalChatRequest, ChatUserContext, iChatUserContext } from '../../core/chatContext';
import { loginUser, logoutUser } from '../../core/users';
import { ChatController } from '../../components/chat/chat.controller';

import { HeaderComponent } from '../../components/common/header/main';
import { MenuComponent } from '../../components/common/menu/main';
import { LogOutComponent } from '../../components/common/menu/logout/logout';
import { NewChatComponent  } from '../../components/common/newChat/newChat';
import { HistoryComponent } from '../../components/history/history';
import { ParametersComponent } from '../../components/common/parameters/parameters';
import { ChatComponent } from '../../components/chat/chat';

import { NOT_AUTH_USER, VORTEX_ID } from '../../configuration/vortexConfig';
import logoHAI from './../../assets/img/Vortex_AI_Title_Blank.svg';

import { useMsal } from '@azure/msal-react';
import { Toaster } from 'react-hot-toast';

const ChatPage: React.FC<{ userId: string, userChatIdx: UserProfile | null, accessToken: string | null }> = ({ userId, userChatIdx, accessToken = null }) => {

    const [currChat, setCurrChat] = useState(InitalChatRequest);
    const [currHistory, setCurrHistory] = useState([]);
    const [userAsks, setUserAsks] = useState([] as Array<string>);
    const [iaAnswers, setIaAnswers] = useState([] as DialogComponents[]);

    useEffect(() => {

        const _registerUser = async () => {
            await loginUser(VORTEX_ID, userId)
        }

        const _handleBeforeUnload = async () => {
            if (currChat.data_sources[0].parameters.ephemeralId) {
                const Chat_Controller = new ChatController()
                await Chat_Controller.RemoveFile(currChat.data_sources[0].parameters.ephemeralId);
            };
            logoutUser(VORTEX_ID, userId);
        };

        // Register login user
        _registerUser();

        // Add user index to request parameters to call openai api
        setCurrChat((currChat: RequestOpenAI) => {
            if (userChatIdx?.index) {
                currChat.data_sources[0].parameters.index_name = userChatIdx.index;
                currChat.data_sources[0].parameters.semantic_configuration = userChatIdx.semanticConfiguration;
            }
            return currChat;
        });

        // Add event before unload
        window.addEventListener('beforeunload', _handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', _handleBeforeUnload);
        };
    }, [userChatIdx, userId, currChat]);

    const NotAutorizedComponent = () => {
        const { instance } = useMsal();

        const handleLogoutRedirect = () => {
            instance.logoutRedirect().catch((error) => console.log(error));
        };

        return (
            <div className={styles.chatError}>
                <div className={styles.chatErrorMessage}>{NOT_AUTH_USER}</div>
                <div className={styles.chatErrorLogOut} onClick={handleLogoutRedirect} role="button">
                    <Logout></Logout>
                    <span>Log Out</span>
                </div>
            </div>
        )
    }

    return (
        //     <Toaster />
            <ChatUserContext.Provider value={
                {
                    currChat, setCurrChat,
                    currHistory, setCurrHistory,
                    userAsks, setUserAsks,
                    iaAnswers, setIaAnswers,
                    accessToken: accessToken
                } as iChatUserContext
            }>
                <div className={styles.chat}>
                    <Toaster></Toaster>
                    <div className={styles.chatContainer}>
                        <HeaderComponent userChatIdx={userChatIdx} headerProps={{srcImage:logoHAI, enabledContext:true}}></HeaderComponent> 
                        {(userChatIdx?.authorizedUser && userChatIdx?.index)
                            ?
                            <>
                                <MenuComponent idUser={userId} status={true}>
                                    <div className={styles.chatMenu}>
                                        <NewChatComponent></NewChatComponent>
                                        <HistoryComponent idUser={userId}></HistoryComponent>
                                        <ParametersComponent></ParametersComponent>
                                        <LogOutComponent></LogOutComponent>
                                    </div>
                                </MenuComponent>
                                <div className={styles.chatMain}>
                                    <ChatComponent idUser={userId} userPhoto={userChatIdx?.userPhoto} />
                                </div>                            
                            </>
                            :
                            <NotAutorizedComponent />
                        } 
                    </div>
                </div>
            </ChatUserContext.Provider>
    )

}

export { ChatPage }