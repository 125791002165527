import React from 'react';
import styles from './loadingScreen.module.scss';

const LoadingScreen: React.FC = () => {
    return (
        <div className={styles.loadingScreen}>
            <div className={styles.loadingContainer}>
                <div className={styles.loadingSpinner}></div>
                <div className={styles.loadingText}>Cargando conversaciones...</div>
            </div>
        </div>
    );
};

export default LoadingScreen;