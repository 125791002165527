/* index.tsx */

import { parserMessage } from "../parser";

import { CHAT_ROLE } from "../../configuration/vortexConfig";
import { fetchWithToken } from "../interceptor";

export async function callHttpApi(aiApiRequest: RequestOpenAI, accessToken: string) {
    let _url = '' 
    if (aiApiRequest.data_sources[0].parameters.ephemeralId) {
        _url = `/v1/ephemeral/completion/${aiApiRequest.data_sources[0].parameters.ephemeralId}`
    } else {
        _url = `/v1/chat/completion`
    }
   
    const _requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            "role": CHAT_ROLE,
            "question": JSON.stringify(aiApiRequest.messages),
            "index": aiApiRequest.data_sources[0].parameters.index_name,
            "semantic_configuration": aiApiRequest.data_sources[0].parameters.semantic_configuration,
            "temperature": aiApiRequest.temperature,
            "max_n_tokens": aiApiRequest.max_tokens,
        }),
    };
    return await fetchWithToken(_url, _requestOptions)
    
}



export async function getAPIResponse(
    bodyApiRequest: RequestOpenAI,
    accessToken: string
): Promise<any> {
    return new Promise( ( resolve, reject) => {
        const apiResponse: any[] = [];
        try {
            callHttpApi(bodyApiRequest, accessToken)
                .then( async (response) => {
                    if (response.status === 200) {
                        const stream = response?.body;
                        // Get the reader from the stream
                        const reader = stream!.getReader();
                        // Define a function to read each chunk
                        const readChunk = () => {
                            // Read a chunk from the reader
                            reader.read()
                                .then(({
                                    value,
                                    done
                                }) => {
                                    // Check if the stream is done
                                    if (done) {
                                        // Log a message
                                        resolve(apiResponse);
                                        // Return from the function
                                        return;
                                    }
                                    // Convert the chunk value to a string
                                    const chunkString = new TextDecoder().decode(value);
                                    // and parse de string
                                    parserMessage(chunkString).then( (chunk) => {
                                        apiResponse.push(...chunk);
                                    })
                                    // Log the chunk string
                                    // Read the next chunk
                                    readChunk();
                                })
                                .catch(error => {
                                    // Log the error
                                    console.error(error);
                                    resolve([{
                                            "index": 0,
                                            "delta": {
                                                "content": "Oops, parece que hay problema de conexión con el servidor central."
                                            },
                                            "end_turn": false,
                                            "finish_reason": null
                                        }
                                    ]);
                                });
                        };
                        // Start reading the first chunk
                        readChunk();
                    } else {
                        resolve([{
                                "index": 0,
                                "delta": {
                                    "content": "Oops, parece que hay problema de conexión con el servidor central."
                                },
                                "end_turn": false,
                                "finish_reason": null
                            }
                        ]);                  
                    }
                })
                .catch((error) => {
                    console.error("Error al realizar la llamada fetch:", error);
                    resolve([{
                        "index": 0,
                        "delta": {
                            "context": {
                                "messages": [
                                    {
                                        "role": "tool",
                                        "content": "Oops, parece que hay problema de conexión con el servidor central.",
                                        "end_turn": false
                                    }
                                ]
                            }
                        },
                        "end_turn": true,
                        "finish_reason": null
                    }]); 
                }); 
        }
        catch ( error ) {
            console.info('Error')
        }
    });

}
