import { DISCLAIMER, VERSION } from '../../../configuration/vortexConfig';

import styles from './footer.module.scss';


const FooterComponent: React.FC = () => {
   
    return (
        <>
            <div className={styles.disclaimerComponent}>
                {DISCLAIMER}
            </div>
            <div className={styles.versionComponent}>
                {VERSION}
            </div>
        </>
    )
}

export { FooterComponent }


