import styles from './insights.module.scss';

// import { BackgroundComponent } from '../../components/common/background/background';
// import { HeaderComponent } from '../../components/common/header/header';
// import { MenuComponent } from '../../components/common/menu/menu';

// import logoHAI from './../../assets/img/Vortex_AI_Title_Blank.svg';


const InsightsPage: React.FC<{userId: string, userChatIdx: UserProfile | null, accessToken: string | null}> = ({userId, userChatIdx, accessToken = null}) => {    
    return (
        // <BackgroundComponent>
        //      <HeaderComponent userChatIdx={userChatIdx} headerProps={{srcImage:logoHAI, enabledContext:false}}></HeaderComponent> 
             <div className={styles.componentContainer}>
                {/* <MenuComponent idUser={userId}/> */}
                {/* <div className={styles.textoContainer}></div> */}
             </div>
        // </BackgroundComponent>
    )
}

export {InsightsPage}
