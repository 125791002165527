import { ChatController } from '../chat.controller';

import styles from './fileContext.module.scss';

import CloseIcon from '@mui/icons-material/Close';


const FileContextComponent: React.FC<{ fileName: string, setFileName: any, currChatContext: any }> = ({ fileName, setFileName, currChatContext }) => {
   
    const handleRemoveFile = () => {
        const Chat_Controller = new ChatController();
        Chat_Controller.RemoveFile(currChatContext.currChat.data_sources[0].parameters.ephemeralId)
            .then(() => {
                // Remove previous conversation
                currChatContext.setUserAsks([]);
                currChatContext.setIaAnswers([]);
                // Remove conversation
                currChatContext.setCurrChat((prevCurrChat: any) => {
                    // Remove chat history
                    prevCurrChat.messages = [];
                    // Indicate start new document
                    prevCurrChat['data_sources'][0]['parameters']['new_document'] = true;
                    // Remove ephemeralId
                    prevCurrChat['data_sources'][0].parameters.ephemeralId = null;       
                    return prevCurrChat;
                }); 
                setFileName('');
        })
        .catch(() => {
            alert("Error al borrar el archivo, pruebe de nuevo.")
        })
    };

    return (
        <div className={styles.fileContextComponent}>
            { fileName && fileName !== "Cargando el fichero. Un momento por favor ..." ? (
                <>
                    <span>{fileName}</span>
                    <span>
                        <CloseIcon className={styles.fileContextComponentIcon} onClick={() => handleRemoveFile()}></CloseIcon>
                    </span>
                </>
            ):  fileName && fileName === "Cargando el fichero. Un momento por favor ..." ? (<span>{fileName}</span>) : null}
        </div>
    )
}

export { FileContextComponent }


