import { useContext, useEffect, useState } from 'react';

import { ChatUserContext } from '../../../../core/chatContext';

import styles from './context.module.scss';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Dropdown: React.FC<{ allowIndexes: AllowIndexes[] | []}> = ({allowIndexes}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([{"id": 0, "label": "Selecciona un contexto"}]);
  const [showContext, setShowContext] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(0);

  const currChatContext: any = useContext(ChatUserContext);

  useEffect(() => {
    // Assign allowindexes to items
    if ( allowIndexes.length) {
      setItems(allowIndexes.map((indexName: AllowIndexes, idx: number) => {
        return {
          "id": idx,
          "label": indexName.description
        }
      }));
      setShowContext(true);
    }
  }, [allowIndexes]
  )

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleItemClick = (id: any) => {
    setSelectedItemId(id === selectedItemId ? selectedItemId : id);
    setIsOpen(!isOpen);
    // Change data in context
    currChatContext.setCurrChat( (currChat: any) => {
      currChat.data_sources[0].parameters.index_name = allowIndexes[id].displayName;
      currChat.data_sources[0].parameters.semantic_configuration = allowIndexes[id].semanticConfiguration;
      return currChat;
    })
  };

  
  const selectedItem = items.find((item) => item.id === selectedItemId);

  const ContextSelectorComponent = () => {
    if ( items.length > 1 ) {
      return (
        <div className={styles.dropdownHeader} onClick={toggleDropdown}>
          {selectedItem ? selectedItem.label : "Selecciona tu contexto"}
          <KeyboardArrowRightIcon  className={`${styles.icon} ${isOpen && styles.open}`}></KeyboardArrowRightIcon>
        </div>
      )
    } else {
      return (
        <div className={styles.dropdownHeader}>
          {selectedItem ? selectedItem.label : "Selecciona tu contexto"}
        </div>
      )
    }

  }

  return (
    <div className={styles.dropdown}>
      {
        showContext && <ContextSelectorComponent></ContextSelectorComponent>
      }
      <div className={`${styles.dropdownBody} ${isOpen && styles.open}`}>
        {items.map((item) => (
          <div
            className={styles.dropdownItem}
            onClick={() => handleItemClick(item.id)}
            key={item.id}
          >
            <span
              className={`${styles.dropdownItemDot} ${
                item.id === selectedItemId && styles.selected
              }`}
            >
              •
            </span>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export { Dropdown };