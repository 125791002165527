/* index.tsx */

import { fetchWithToken } from "../interceptor";

export async function loginUser(app_id: string, user_id: string) {
    return new Promise( async(resolve: any) => {
        await fetchWithToken(`/v1/users/login/${app_id}/${user_id}`);
        resolve(true)
    })
}

export function logoutUser(app_id: string, user_id: string): void{
    ( async(app_id: string, user_id: string) => {
        await fetchWithToken(`/v1/users/logout/${app_id}/${user_id}`);
        sessionStorage.clear();
    })(app_id, user_id);

}