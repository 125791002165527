import { useState } from 'react';

import { ChatUserContext, iChatUserContext, InitalChatRequest } from '../../core/chatContext';

import { HeaderComponent } from '../../components/common/header/main';
import { MenuComponent } from '../../components/common/menu/main';
import { LogOutComponent } from '../../components/common/menu/logout/logout';
import { AiOperatorComponent } from '../../components/ai-operator/main';

import styles from './main.module.scss';
import logoHAI from './../../assets/img/Vortex_AI_Title_Blank.svg';

const AiOperatorNetworkPage: React.FC<{userId: string, userChatIdx: UserProfile | null, accessToken: string | null}> = ({userId, userChatIdx, accessToken = null}) => {    
    
    const [currChat, setCurrChat] = useState(InitalChatRequest);
    const [currHistory, setCurrHistory] = useState([]);
    const [userAsks, setUserAsks] = useState([] as Array<string>);
    const [iaAnswers, setIaAnswers] = useState([] as DialogComponents[]);
    
    return (
        <ChatUserContext.Provider value={
            {   
                currChat, setCurrChat, 
                currHistory, setCurrHistory, 
                userAsks, setUserAsks, 
                iaAnswers, setIaAnswers, 
                accessToken: accessToken
            } as iChatUserContext
        }>
            <div className={styles.aiOperator}>
                <div className={styles.aiOperatorContainer}>
                    <HeaderComponent userChatIdx={userChatIdx} headerProps={{srcImage:logoHAI, enabledContext:false}}></HeaderComponent> 
                    <MenuComponent idUser={userId} status={false}>
                        <div className={styles.aiOperatorMenu}>
                            <LogOutComponent></LogOutComponent>
                        </div>
                    </MenuComponent>
                    <div className={styles.aiOperatorMain}>
                        <AiOperatorComponent idUser={userId} userPhoto={userChatIdx?.userPhoto}></AiOperatorComponent>
                    </div>
                </div>
            </div>
        </ChatUserContext.Provider>            
    )
}

export { AiOperatorNetworkPage }

