// chat.tsx
import { useState, useContext, createRef, useEffect, Fragment } from 'react';

import { ChatUserContext } from '../../core/chatContext';
import { ChatController } from './chat.controller';

import { WelcomeComponent } from '../common/welcome/main';
import { DialogComponent } from '../common/dialog';
import { FileContextComponent  } from './fileContext/fileContext';
import { FooterComponent } from './footer/footer';
import toast, {Toaster} from 'react-hot-toast';
import styles from './chat.module.scss';

import Send from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';


const ChatComponent: React.FC<{ idUser: any, userPhoto: any }> = ({ idUser, userPhoto }) => {

  const Chat_Controller = new ChatController();

  const currChatContext: any = useContext(ChatUserContext);

  const textAreaRef = createRef<HTMLTextAreaElement>();

  const [fileName, setFileName] = useState('');
  
  useEffect(() => {
    // Focus in textarea when render page
    textAreaRef.current?.focus();
  });

  const PromptComponent = () => {
    const [formData, setFormData] = useState({ question: "" });

    const handleTextareaKeyPress = (e: any) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();

      if (formData.question) {
        // Add new question to array
        currChatContext['setCurrChat']((prevContext: RequestOpenAI) => {
          prevContext.messages = [...prevContext.messages || [], {
            "role": "user",
            "content": formData.question
          }]
          return prevContext
        });

        // Update user question
        currChatContext.setUserAsks((prevArray: any) => [...prevArray, formData.question]);

        // Add loading message
        currChatContext.setIaAnswers((prevArray: DialogComponents[]) => [...prevArray, {id: '0', content: 'Generando respuesta...'}]);

        Chat_Controller.proccessResponse(currChatContext, idUser, formData.question);
      }
    };

    const handleFileChange = (event: any) => {
      const _file = event.target.files[0]
      const _formData = new FormData();
      _formData.append('pdf_file', _file);

      if (Chat_Controller.checkFileIsPdf(_file) && Chat_Controller.checkFileSize(_file.size)) {
        currChatContext.currChat.data_sources[0].parameters.ephemeralId = crypto.randomUUID();
        setFileName('Cargando el fichero. Un momento por favor ...');
        Chat_Controller.uploadFile(_formData, currChatContext.currChat.data_sources[0].parameters.ephemeralId)
          .then(() => {
            // Create new conversation context
            // Remove previous conversation
            currChatContext.setUserAsks([]);
            currChatContext.setIaAnswers([]);
            // Remove conversation
            currChatContext.setCurrChat((prevCurrChat: any) => {
                // Remove chat history
                prevCurrChat.messages = [];
                // Indicate start new document
                prevCurrChat['data_sources'][0]['parameters']['new_document'] = true;
                // Remove ephemeralId
                prevCurrChat['data_sources'][0].parameters.ephemeralId = null;       
                return prevCurrChat;
            }); 
            setFileName(_file['name']);
          });
      } else {
        toast.error('El fichero supera el tamaño máximo permitido o no es del tipo correcto');
      }
    };

    return (
        <Fragment>
            <Toaster></Toaster>
            <FileContextComponent fileName={fileName} setFileName={setFileName} currChatContext={currChatContext}></FileContextComponent>
            <div className={styles.promptComponent}>
                <form className={styles.promptComponentInput} onSubmit={handleSubmit}>
                    <div className={styles.promptComponentUploadfile}>
                        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                            <div className={styles.promptComponentUploadButton}>
                                <AttachFileIcon className={styles.promptComponentIcon}></AttachFileIcon>
                            </div>
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className={styles.promptComponentInputTextareaContainer}>
                        <textarea 
                            ref={textAreaRef}
                            className={styles.promptComponentInputTextarea} 
                            onChange={(e) => {
                              setFormData({...formData, question: e.target.value})
                              e.target.style.height = 'auto'
                              e.target.style.height = `${e.target.scrollHeight}px`
                            }}
                            onKeyDown={handleTextareaKeyPress} // Handles the "Enter" key
                            value={formData.question} 
                            id="body"
                            name="body" 
                            placeholder='En qué te puedo ayudar'
                            rows={1}
                            style={{overflowY: 'auto'}}></textarea>
                    </div>
                    <button className={styles.promptComponentSendButton} type='submit'>
                        <Send className={styles.promptComponentIcon}></Send>
                    </button>
                </form>
            </div>
        </Fragment>
    )
  }

  const showMainComponent = () => {
    if ( !currChatContext.currChat.hasOwnProperty('messages') || currChatContext.currChat.messages.length === 0) {
        return (
            <WelcomeComponent/>
        )
    } else {
        return (
            <DialogComponent 
              userPhoto={userPhoto}
              userAsks={currChatContext.userAsks}
              iaAnswers={currChatContext.iaAnswers}
              enabledFeedBack={true}
            ></DialogComponent>
        )
    }
  }


  return(
      <div className={styles.chatComponent}>
          <>
            {showMainComponent()}
            <PromptComponent />
            <FooterComponent />
          </>
      </div>
  )
}

export { ChatComponent }
