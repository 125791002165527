/* index.ts */

export function parserMessage(chunkString: string): Promise<any> {
    return new Promise( (resolve) => {
        // Split chunk string in data blocks
        const chunkResponse: string | any[] = [];
        try {
            const chunks = chunkString.split('\n');
            chunks.forEach( (chunckString: any) => {
                try {
                    if ( chunckString ) {
                        const chunck = JSON.parse(chunckString);
                        if (chunck.hasOwnProperty('id') && chunck['id']) {
                            chunkResponse.push(chunck['choices'][0]);
                        }
                    }
                }
                catch(err) {
                    console.error(err);
                }
            })
        }
        finally {
            resolve(chunkResponse);
        }
    })
}

