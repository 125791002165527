import { useState } from 'react';

import { PopUpParameters } from './popUpParameters/popUpParameters';

import styles from './parameters.module.scss';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';


const ParametersComponent = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleIsVisible = () => {
        // Disable parameters menu
        setIsVisible(!isVisible);
    };

    const handleInvisible = () => {
        setIsVisible(false)
    } 

    return (
        <ClickAwayListener onClickAway={handleInvisible}>
        <div className={styles.parameters}>
            <div className={styles.parametersContainer} onClick={handleIsVisible} role="button">
                <SettingsOutlinedIcon></SettingsOutlinedIcon>
                <span>Ajustes</span>
            </div>
            {isVisible ? (
                <PopUpParameters visible={isVisible} setIsVisibleParams={setIsVisible}></PopUpParameters>
            ) : null}
        </div>   
        </ClickAwayListener>
    )
}

export { ParametersComponent } 