class VortexInstance  {
  constructor() {
    this._vortexClient = '';
  }

  private _vortexClient: string;

  saveToSessionStorage() {
    sessionStorage.setItem('vortexInstance', JSON.stringify(this));
  }

  static loadFromSessionStorage(): VortexInstance | null {
    const storedInstance = sessionStorage.getItem('vortexInstance');
    if (storedInstance) {
      const instance = new VortexInstance();
      instance._vortexClient = JSON.parse(storedInstance)._vortexClient;
      return instance;
    }
    return null;
  }

  get vortexClient(): string {
    return this._vortexClient;
  }

  set vortexClient(vortexClient: string) {
    this._vortexClient = vortexClient;
  }
}

export { VortexInstance } 