
import { useContext } from 'react';

import { ChatUserContext, iChatUserContext } from '../../core/chatContext';

import { WelcomeComponent } from '../common/welcome/main';
import { FooterComponent } from '../chat/footer/footer'; 
import { PromptWithOutAttachedComponent } from '../common/prompt';
import { DialogComponent } from '../common/dialog';

const AiOperatorComponent: React.FC<{ idUser: any, userPhoto: any }> = ({ idUser, userPhoto }) => {

    const currChatContext: iChatUserContext | null = useContext(ChatUserContext);

    const showMainComponent = () => {
        if ( !currChatContext?.currChat.hasOwnProperty('messages') || currChatContext.currChat.messages.length === 0) {
            return (
                <WelcomeComponent/>
            )
        } else {
            return (
                <DialogComponent 
                    userPhoto={userPhoto} 
                    userAsks={currChatContext.userAsks} 
                    iaAnswers={currChatContext.iaAnswers}
                    enabledFeedBack={false}
                ></DialogComponent>
            )
        }
    }

    return (
          <>
              {showMainComponent()}
              <PromptWithOutAttachedComponent></PromptWithOutAttachedComponent>
              <FooterComponent />
          </>
    )
}

export {AiOperatorComponent}

