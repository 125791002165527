// ChatContext/index.ts
import { createContext, useContext } from "react";

import { API_DATA_SOURCES, API_PARAMETERS } from "../../configuration/vortexConfig"; 

export interface iChatUserContext {
  currChat: any;
  setCurrChat: (currChat: any) => void;
  currHistory: any;
  setCurrHistory: (currHistory: any) => void;
  userAsks: any;
  setUserAsks: (userAsks: any) => void;
  iaAnswers: any;
  setIaAnswers: (iaAnswers: any) => void;
  accessToken: string | null;
}

export const ChatUserContext = createContext<iChatUserContext | null>(null);

export const InitalChatRequest = {
    data_sources: [API_DATA_SOURCES],
    ...API_PARAMETERS,
}

export const useAppContext = () => {
    return useContext(ChatUserContext);
}

