// menu.scss
import { useState, ReactNode } from 'react';

import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';


import styles from './main.module.scss';

interface HeaderElementProps {
    isMenuOpen: boolean;
    toggleMenu: () => void;
  }

const HeaderElement: React.FC<HeaderElementProps> = ({ isMenuOpen, toggleMenu}) => {
    return(
        <div className={styles.menuIcon} onClick={toggleMenu} role="button">
            <div className={styles.menuIconMenu} style={{ display: isMenuOpen ? 'none' : 'block' }}>
                <Menu style={{fill: "#2c2a29"}}></Menu>
            </div>
            <div className={styles.menuIconClose} style={{ display: isMenuOpen ? 'block' : 'none' }}>
                <Close style={{fill: "#2c2a29"}}></Close>
            </div>
        </div>
    )
}

const MenuComponent: React.FC<{idUser: string, status: boolean, children: ReactNode}> = ({ idUser, status, children }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(status);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    };

    return(
        <div className={`${styles.menu} ${isMenuOpen ? styles.menuIsActive : ''}`}>
            <HeaderElement isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                {isMenuOpen && (
                    <>
                        {children}
                    </>
                )}
        </div>
    )
}

export { MenuComponent }