import { useMsal } from '@azure/msal-react';

import styles from './logout.module.scss';
import Logout from '@mui/icons-material/Logout';

const LogOutComponent = () => {
    const { instance } = useMsal();

    const handleLogoutRedirect = () => {
        instance.logoutRedirect().catch((error) => console.log(error));
    };

    return (
        <div className={styles.logout}>
            <div className={styles.logoutContainer} onClick={handleLogoutRedirect} role="button">
                <Logout></Logout>
                <span>Log Out</span>
            </div>
        </div>
    )
}

export { LogOutComponent } 