import styles from './feedBack.module.scss';

import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';



const FeedbackComponent: React.FC<{conversationId: string, idx: any}> = ({ conversationId, idx}) => {

    const evaluateResponse = (action: string, conversationId: string, idx: any) => {
        console.info(action);
        console.info(conversationId);
        console.info(idx);
    }

    return (
        <div className={styles.dialogFeedback}>
            <span className={styles.dialogFeedback__thumbdown} onClick={() => evaluateResponse('down', conversationId, idx)}>
                <ThumbDownOffAltIcon></ThumbDownOffAltIcon>
            </span>
            <span className={styles.dialogFeedback__thumbup} onClick={() => evaluateResponse('up', conversationId, idx)}>
                <ThumbUpOffAltIcon></ThumbUpOffAltIcon>
            </span>
        </div>
    )
}

export { FeedbackComponent }

