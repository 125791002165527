import { Fragment } from 'react/jsx-runtime';
import { useContext } from 'react';


import { Conversation } from '../../../core/cosmos';
import { ChatUserContext } from '../../../core/chatContext';
import { ChatMessage } from '../../../core/cosmos';

import styles from './conversation.module.scss';

const ConversationComponent: React.FC<{ userConversation: Conversation[] }> = ({ userConversation }) => {
    const currChatContext: any = useContext(ChatUserContext);

    const selectConversation = (conversation: Conversation, conversationIdx: number) => {
        // Create messages context
        const messages: {role: string, content: string}[] = [];

        for ( let idx = conversationIdx ; idx < conversation.messages.length; idx++) {

            if ( conversation.messages[idx].role === 'user') currChatContext.setUserAsks( (prevArray: any) => [...prevArray, conversation.messages[idx].content]);
            if ( conversation.messages[idx].role === 'assistant') currChatContext.setIaAnswers( (prevArray: DialogComponents[]) => {
                return [...prevArray, {
                    "id": conversation.messages[idx].id,
                    "content": conversation.messages[idx].content
                }];
            });
            
            // Add history messages to current context
            if ( messages ) messages.push(
                {
                    role: conversation.messages[idx].role,
                    content:  conversation.messages[idx].content
                })
        }

        // Update context with new data
        if ( messages.length ) {
            currChatContext.setCurrChat( (currChat: any) => {
                // Save conversationId
                currChat.data_sources[0].parameters.conversationId = conversation.id
                // Save selected messages
                currChat.messages = messages;
                return currChat;
            });
        }
    }

    return(
        <div className={styles.conversations}>
            <div className={styles.conversationsTitle}>Historial Conversaciones</div>
            {
                userConversation?.length 
                    ? (
                        userConversation.map((conversation: Conversation, index: number) => (  
                            <Fragment key={index}>
                                <div className={styles.conversationsSubtitleGroup}>
                                    <div className={styles.conversationsSubtitle}>{(new Date(conversation.date)).toLocaleDateString('es-ES', 
                                        {   year: "numeric",
                                            month: "long",
                                            day: "numeric"
                                        })}</div>
                                    <div className={styles.conversationsSubtitleHour}>{new Date(conversation.date).toLocaleTimeString()}</div>  
                                </div>
                                {
                                    conversation.messages.map( (message: ChatMessage, index: number) => {
                                        if ( message.role === 'user') return ( 
                                            <div key={index} className={styles.conversationsQuestion} onClick={() => {
                                                selectConversation(conversation, index)
                                            }} role="button">
                                                {message.content}
                                            </div>
                                        )
                                        return null
                                    })
                                }
                            </Fragment>
                        ))
                    )
                    : null
            }
        </div>
    )
}

export { ConversationComponent }


