import { useContext } from 'react';
import { ChatUserContext } from '../../../core/chatContext';

import styles from './newChat.module.scss';

import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';


const NewChatComponent: React.FC = () => {
    const currChatContext: any = useContext(ChatUserContext);

    const newConversation = () => {
        currChatContext.setCurrChat((prevCurrChat: any) => {
            prevCurrChat.messages = [];
            prevCurrChat['data_sources'][0]['parameters']['new_document'] = true;
            prevCurrChat['data_sources'][0]['parameters']['conversationId'] = crypto.randomUUID();
            return prevCurrChat;
        });

        currChatContext.setUserAsks([]);
        currChatContext.setIaAnswers([]);
    }

    return (
        <div className={styles.newChatComponent} onClick={() => {newConversation()}} role="button">
            <span>
                <AddCommentOutlinedIcon ></AddCommentOutlinedIcon>
            </span>
            <span>
                Nueva conversación
            </span>
        </div>
    )
}

export { NewChatComponent }


 