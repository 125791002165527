import styles from './main.module.scss';
import logo from '../../../assets/img/AF_LOGO_SEAT_SA_RGB.svg';


const WelcomeComponent: React.FC = () => {
   
    return (
      <div className={styles.welcomeComponent}>
        <img src={logo} className={styles.welcomeComponentLogo} alt="logo" />
      </div>
    )
}

export { WelcomeComponent }


