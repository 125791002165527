// history.scss
import { useContext, useEffect, useState } from 'react';

import { ConversationComponent } from './conversation/conversation';

import { CosmosConn } from '../../core/cosmos/api';
import { ChatUserContext } from '../../core/chatContext';

import { User } from './iHistory';
import LoadingScreen from '../history/loadingScreen/loadingScreen';
import styles from './history.module.scss';
// import Menu from '@mui/icons-material/Menu';
// import Close from '@mui/icons-material/Close';
import toast from 'react-hot-toast';

// interface HeaderElementProps {
//     isMenuOpen: boolean;
//     toggleMenu: () => void;
//   }

// const HeaderElement: React.FC<HeaderElementProps> = ({ isMenuOpen, toggleMenu}) => {
//     return(
//         <div className={styles.historyIcon} onClick={toggleMenu} role="button">
//             <div className={styles.historyIconMenu} style={{ display: isMenuOpen ? 'none' : 'block' }}>
//                 <Menu style={{fill: "#2c2a29"}}></Menu>
//             </div>
//             <div className={styles.historyIconClose} style={{ display: isMenuOpen ? 'block' : 'none' }}>
//                 <Close style={{fill: "#2c2a29"}}></Close>
//             </div>
//         </div>
//     )
// }

const HistoryComponent: React.FC<User> = ({ idUser }) => {

    const currChatContext: any = useContext(ChatUserContext);
    const [loading, setLoading] = useState(true);
    const [isMenuOpen] = useState(true);
    const Cosmos_Connection = new CosmosConn();

    // const toggleMenu = () => {
    //     setIsMenuOpen(!isMenuOpen)
    // };

    useEffect(() => {
        if ( idUser ) {
            // Get user history and to context
            Cosmos_Connection.fetchChatHistoryInit(idUser)
                .then( mongoResponse => {
                    currChatContext.setCurrHistory(mongoResponse?.conversation?.reverse() ||  []);
                    setLoading(false);
                })
                .catch( mongoResponse => {
                    toast.error("Error al cargar las conversaciones...", {
                        duration: 8000,
                        style: {
                            border: '1px black',
                            background: '#CD5C5C',
                            color: 'white',
                            padding: '20px'
                        }
                    })
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        idUser
    ])

    return(
        <>
        {loading ? <LoadingScreen/> :      
        (
            <div className={`${styles.history} ${isMenuOpen ? styles.historyIsActive : ''}`}>
                {isMenuOpen && (
                    <>
                        <ConversationComponent userConversation={currChatContext.currHistory} />
                    </>
                )}
            </div>
        )}
      </>    
    )
}

export { HistoryComponent}